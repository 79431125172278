<template>
 <div>
  <svg viewBox="0 0 183 183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="userSpaceOnUse" id="Naamloos_verloop_2" x1="109.79" x2="70.48" y1="137.68" y2="50.24">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="Laag 2" id="Laag_2">
    <g data-name="Laag 1" id="Laag_1-2">
     <rect :fill="background" height="183" width="183"/>
     <path d="M119.63,32.23c-19.14,0-34.79,12.45-34.79,27.83a25.08,25.08,0,0,0,9.57,19.13c0,2.09-3.22,9-3.46,10.07s1.28,1.12,1.28,1.12,9.3.54,15.12-4.23a44.27,44.27,0,0,0,12.28,1.74c19.13,0,34.79-12.45,34.79-27.83S138.76,32.23,119.63,32.23Zm0,48.7c-15.38,0-27.83-9.35-27.83-20.87s12.45-20.87,27.83-20.87,27.83,9.36,27.83,20.87S135,80.93,119.63,80.93Zm-78.48-2A7.19,7.19,0,1,1,34,86.1a7.18,7.18,0,0,1,7.18-7.18h0m-5.38,18H46.54a7.19,7.19,0,0,1,7.19,7.19v19.76H48.34v26.94H34V123.83H28.58V104.07a7.19,7.19,0,0,1,7.19-7.19m37.72-18A7.19,7.19,0,1,1,66.3,86.1a7.18,7.18,0,0,1,7.19-7.18h0m-5.39,18H78.88a7.19,7.19,0,0,1,7.19,7.19h0v19.76H80.68v26.94H66.3V123.83H60.91V104.07A7.19,7.19,0,0,1,68.1,96.88Z" style="fill:url(#Naamloos_verloop_2);"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>